<template>
    <div v-if="data && data.filter(e => e.DocumentRecordId).length > 0" class="p-2 border mb-3">
        <div class="flex items-center justify-between">
            <div>研究任務檔案</div>
        </div>
        <div class="table w-full p-2">
            <table class="w-full border table">
                <thead>
                    <tr class="bg-gray-50 border-b">
                        <th class="p-2 border-r text-sm font-thin">
                            <div class="flex items-center justify-center">名稱</div>
                        </th>
                        <th class="p-2 border-r text-sm font-thin">
                            <div class="flex items-center justify-center">功能</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center border-b text-sm" v-for="(item, index) in data?.filter(e => e.DocumentRecordId).slice(0, 1)" :key="index">
                        <td class="p-2 border-r">{{ item.Name }}</td>
                        <td>
                            <button type="button"
                                class="bg-blue-500 p-2 text-white rounded-lg hover:shadow-lg text-sm mr-3"
                                @click="download(item.Id)">下載</button>
                            <button v-if="canDelete && (item.PublicationId != null && item.DocumentId == null)" type="button"
                                class="bg-red-500 p-2 text-white rounded-lg hover:shadow-lg text-sm mr-3"
                                @click="remove(item.Id, item.DocumentId)">刪除</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="p-2 border">
        <div class="flex items-center justify-between">
            <div>{{ title }}</div>
            <div v-if="canUpload">
                <file-upload class="mt-2" :multiple="multiple" v-model="uploadFiles" @change="upload">
                    <button type="button" class="button rounded-lg bg-theme-1 text-white">
                    上傳檔案
                    </button>
                </file-upload>
            </div>
        </div>
        <div class="table w-full p-2">
            <table class="w-full border table">
                <thead>
                    <tr class="bg-gray-50 border-b">
                        <th class="p-2 border-r text-sm font-thin">
                            <div class="flex items-center justify-center">名稱</div>
                        </th>
                        <th class="p-2 border-r text-sm font-thin">
                            <div class="flex items-center justify-center">功能</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center border-b text-sm" v-for="(item, index) in data?.filter(e => e.PublicationId)" :key="index">
                        <td class="p-2 border-r">{{ item.Name }}</td>
                        <td>
                            <button type="button"
                                class="bg-blue-500 p-2 text-white rounded-lg hover:shadow-lg text-sm mr-3"
                                @click="download(item.Id)">下載</button>
                            <button v-if="canDelete && (item.PublicationId != null && item.DocumentId == null)" type="button"
                                class="bg-red-500 p-2 text-white rounded-lg hover:shadow-lg text-sm mr-3"
                                @click="remove(item.Id, item.DocumentId)">刪除</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { defineComponent, ref, onMounted, PropType } from '@cloudfun/core'
import FileUpload, { VueUploadItem } from 'vue-upload-component';

interface FileDto {
    Id: number;
    Name: string;
    PublicationId?: number;
    DocumentId?: number;
    DocumentRecordId?: number;
}

export default defineComponent({
    name: "PublicationFileUpload",
    components: {
        FileUpload
    },
    props: {
        canDelete: {
            type: Boolean,
            default: true
        },
        canUpload: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: true
        },
        promises: {
            type: Object as PropType<{
                query(): Promise<FileDto[]>;
                insert(data: FormData): Promise<FileDto[]>,
                delete(id: number): Promise<void>
            }>,
            required: true
        },
        validExt: {
            type: Array,
            default: () => ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.zip', '.rar', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls', '.csv', '.txt', '.pdf']
        },
        validSize: { // Bytes
            type: Number,
            default: 25 * 1024 * 1024
        },
        title: String
    },
    setup(props) {
        const uploadFiles = ref<VueUploadItem[]>([]);
        const fileUpload = ref({});
        const data = ref<FileDto[]>();

        const refresh = async () => {
            try {
                data.value = await props.promises?.query()
            } catch (error: any) {
                data.value = []
                CloudFun.send("error", {
                    subject: "執行失敗",
                    content: error,
                });
            }

        }
        onMounted(() => {
            refresh();
        });

        const upload = () => {
            if (uploadFiles.value.length === 0) return;
            const formData = new FormData();
            for (const file of uploadFiles.value) {
                if (!file.size || !file.name || !file.file) return;
                if (file.size >= props.validSize) {
                    CloudFun.send('error', { subject: '附件上傳失敗！', content: '檔案大小不得超過25M' })
                    uploadFiles.value = [];
                    return;
                }

                const ext = '.' + file.name.split('.')?.[1];
                if (props.validExt && props.validExt.indexOf(ext) === -1) {
                    uploadFiles.value = [];
                    CloudFun.send('error', { subject: '附件上傳失敗！', content: '不支援此檔案類型' })
                    return;
                }
                formData.append("files", file.file);
            }

            props.promises?.insert(formData).then(
                () => {
                    CloudFun.send('info', { subject: '執行成功', content: '上傳完成' })
                    refresh();
                },
                failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
            ).finally(() => {
                uploadFiles.value = [];
            });
        }

        const download = (id: number) => {
            window.open(`${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`)
        }

        const remove = (id: number, documentId: any) => {
            if(documentId) return;
            props.promises?.delete(id).then(
                () => refresh(),
                failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
            );
        }

        return {
            fileUpload,
            data,
            uploadFiles,
            refresh,
            upload,
            download,
            remove
        }
    },
    methods: {
        getData() {
            return this.data;
        }
    }
})
</script>
