<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">出版品管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="() => { isDisabled = false; grid.addNewRow(); }">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex xl:justify-center sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
              <vxe-select v-model="publicationStatus" placeholder="選擇狀態" class="w-60" @change="grid.refresh">
                <vxe-option label="全部狀態"></vxe-option>
                <vxe-option
                  v-for="(item, index) in Object.values($enums.PublicationStatus).filter(e => e.Value !== $enums.PublicationStatus.Revoked.Value)"
                  :key="'publication-status' + index" :label="item.Name" :value="item.Value" class="py-3"></vxe-option>
              </vxe-select>
            </div>
            <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0 w-60">
              <select-box v-bind="allProgramSelectorOptions" v-model="programIds" />
            </div>
            <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0 w-60">
              <select-box v-bind="allUserSelectorOptions" v-model="userIds" />
            </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0 w-60">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit" @reset="(row, callback) => {
          callback();
          row.Abstract = {Content:''};
          row.Chapter = {Content:''};
          row.Diagram = {Content:''};
          row.Form = {Content:''};
          row.Content = {Content:''};
        }" @addNewRow="onGridAdd" @removeSelectedRows="hideBatchDropDown()">
          <template #feedback="{ row }">
            <vxe-button type="button" content="查看" @click="feedbackModal.show(row)"></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submitData(row, submit)">
              <template #column-program-ids="{ data }">
                <select-box ref="editRowProgramSelect" v-bind="programSelectorOptions" v-model="data.ProgramIds"
                  class="custom-vue-selector" :readonly="disableProgramSelect || hasApproved(data.Status)" />
              </template>
              <template #column-keywords="{ data }">
                <select-box class="custom-vue-selector" v-bind="keywordSelectorOptions" v-model="data.KeywordIds" />
              </template>
              <template #column-report-user-id="{ data }">
                <select-box v-bind="userSelectorOptions" v-model="data.ReportUserId" />
              </template>
              <template #column-proof-user-id="{ data }">
                <select-box v-bind="userSelectorOptions" v-model="data.ProofUserId" />
              </template>
              <template #column-review-user-id="{ data }">
                <select-box v-bind="userSelectorOptions" v-model="data.ReviewUserId" />
              </template>
              <template #column-file-upload>
                <PublicationFileUpload v-if="row.Id" ref="fileGrid" :title="'出版品檔案'" v-bind="fileModal.uploadOptions"
                  :canUpload="!hasApproved(fileModal.selectedRow.Status)"
                  :canDelete="!hasApproved(fileModal.selectedRow.Status)" />
              </template>
              <template #column-authors-list>
                <AuthorTable ref="authorTable" :data="row.Authors" />
              </template>
              <template #html-editer="{ data }">
                <div class="mb-4" v-if="data.Abstract">
                  <p class="mb-2">摘要</p>
                  <CKEditor v-model="data.Abstract.Content" :editor="classicEditor" :config="AbstractConfig" />
                </div>
                <div class="mb-4" v-if="data.Chapter">
                  <p class="mb-2">章節列表</p>
                  <CKEditor v-model="data.Chapter.Content" :editor="classicEditor" :config="ChapterConfig" />
                </div>
                <div class="mb-4" v-if="data.Diagram">
                  <p class="mb-2">圖表目錄</p>
                  <CKEditor v-model="data.Diagram.Content" :editor="classicEditor" :config="DiagramConfig" />
                </div>
                <div class="mb-4" v-if="data.Form">
                  <p class="mb-2">表格列表</p>
                  <CKEditor v-model="data.Form.Content" :editor="classicEditor" :config="FormConfig" />
                </div>
                <div class="mb-4" v-if="data.Content">
                  <p class="mb-2">內文</p>
                  <CKEditor v-model="data.Content.Content" :editor="classicEditor" :config="ContentConfig" />
                </div>
              </template>
            </vxe-form>
          </template>
          <template v-if="grid.editingRow && !hasApproved(grid.editingRow.Status)" #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" :disabled="isDisabled" @click="footerSubmit()">
            </vxe-button>
            <vxe-button type="reset" content="重置" :disabled="isDisabled" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-title="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(column, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(column, option, $panel)" />
              </div>
            </div>
          </template>
          <!-- <template #file-list="{ row }">
            <vxe-button type="button" content="查看" @click="fileModal.show(row)"></vxe-button>
          </template> -->
          <template #operate="{ row }">
            <div class="flex justify-center">
              <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯" @click.stop="() => { isDisabled = false; grid.edit(row); }">
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button>
              <button type="button" class="button rounded-full bg-red-600 text-white" title="刪除"
                @click.stop="grid.remove(row)">
                <FontAwesome icon="trash" class="w-4 h-4" />
              </button>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal v-model="feedbackModal.visible" width="80%" height="700" title="出版品迴響" show-zoom="true">
      <FeedbackTable ref="feedbackGrid" v-bind="feedbackModal.gridOptions" :sid="feedbackModal.selectedRow.Id" />
    </vxe-modal>
    <!-- <vxe-modal v-model="newRowModal.visible" width="70%" showFooter title="新增出版品">
      <vxe-form ref="newRowForm" v-bind="newRowFormOptions" :data="newRowModal.formData" @reset="newRowModal.reset"
        @submit="newRowModal.submit">
        <template #column-programs="{ data }">
          <select-box ref="newRowProgramSelect" v-bind="programSelectorOptions" v-model="data.ProgramIds" />
        </template>
        <template #column-keywords="{ data }">
          <select-box v-bind="keywordSelectorOptions" v-model="data.KeywordIds" />
        </template>
        <template #column-report-user-id="{ data }">
          <select-box v-bind="userSelectorOptions" v-model="data.ReportUserId" />
        </template>
      </vxe-form>
      <template #footer>
        <vxe-button type="submit" status="primary" content="確認"
          @click="$refs.newRowForm.validate((errMap) => { if (errMap === undefined) $refs.newRowForm.dispatchEvent('submit'); })">
        </vxe-button>
        <vxe-button type="reset" content="重置" @click="$refs.newRowForm.dispatchEvent('reset')"></vxe-button>
      </template>
    </vxe-modal> -->
    <!-- <vxe-modal v-model="fileModal.visible" width="70%" title="檔案列表">
      <PublicationFileUpload ref="fileGrid" v-bind="fileModal.uploadOptions"
        :canUpload="!hasApproved(fileModal.selectedRow.Status)"
        :canDelete="!hasApproved(fileModal.selectedRow.Status)" />
    </vxe-modal> -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import AuthorTable from "@/components/publication/AuthorTable.vue";
import FeedbackTable from "@/components/publication/FeedbackTable.vue";
import PublicationFileUpload from "@/components/publication/PublicationFileUpload.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import { Ref, watch } from 'vue';
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AuthorTable,
    PublicationFileUpload,
    FeedbackTable,
  },
  setup() {
    const model = CloudFun.current?.model;
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({});
    const disableProgramSelect = ref(false);
    const selectedTopicType = ref<number>(0);
    const newRowProgramSelect = ref<any>();
    const editRowProgramSelect = ref<any>();
    const publicationStatus = ref();
    const programIds = ref();
    const userIds = ref();
    const isDisabled = ref(false);

    const onTopicTypeChange = async (instance: Ref<any>, data: any) => {
      //console.log("topic", data)
      selectedTopicType.value = data.TopicType;
      data.ProgramIds = [];
      disableProgramSelect.value = (await model!.dispatch("program/getChildOnly", { mode: "count", type: selectedTopicType.value })) < 1;
      //console.log(disableProgramSelect.value)
      if (instance.value && instance.value.grid.refresh) {
        instance.value.grid.refresh();
      }
    };

    const setTopicType = async (instance: Ref<any>) => {
      if (instance.value && instance.value.grid.refresh) {
        instance.value.grid.refresh();
      }
    };

    const gridOptions: GridOptions = {
      stripe: false,
      title: '出版品',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      columns: [
      {
        field: 'IndustryCategoryName',
        title: '產業類別',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: false,
      },
      {
        field: 'Title',
        title: '標題',
        width: '40%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-title' }
      },
      {
        field: 'ReportUser.Name',
        title: '撰寫人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'Document.ProofUser.Name',
        title: '校稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'Document.ReviewUser.Name',
        title: '審稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'PublishedDate',
        title: '出版截止時間',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd')
      },
      {
        field: 'Status',
        title: '狀態',
        // width: '100',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.PublicationStatus).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      // {
      //   title: '檔案列表',
      //   width: '80',
      //   slots: { default: "file-list" }
      // },
      // {
      //   field: 'TopicType',
      //   title: '主題',
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.TopicType).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      // {
      //   title: '迴響資料',
      //   width: '100',
      //   slots: { default: "feedback" }
      // },
      ],
      promises: {
        query: model ? (params) => {
          let condition = new Condition("Status", Operator.NotEqual, (model.enums.PublicationStatus as any).Revoked.Value);
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if (publicationStatus.value != null) {
            condition = condition.and("Status", Operator.Equal, publicationStatus.value).and(params.condition!);
          }
          if (programIds.value != null && programIds.value.length) {
            // console.log(programIds.value)
            condition = condition.and("DocumentPrograms.ProgramId", Operator.Contains, programIds.value.join()).and(params.condition!);
          }
          if (userIds.value != null && userIds.value.length) {
            // console.log(userIds.value)
            condition = condition.and("ReportUserId", Operator.Contains, userIds.value.join()).and(params.condition!);
          }
          params.condition?.and(condition);
          return model.dispatch('publication/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('publication/query') : undefined,
        save: model ? (params) => model.dispatch('publication/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "80%", showFooter: true },
      treeConfig: { children: 'Children' }
    }

    const newRowForm = ref(null);
    const newRowModal = reactive({
      visible: false,
      formData: {
        TopicType: 0,
        ContentType: "",
        SourceType: "",
      },
      submit() {
        if (!model) return;
        model.dispatch('publication/insert', newRowModal.formData)
          .then(() => {
            // newRowModal.formData = {
            //   ProofUserId: user?.ProofUserId,
            //   ReviewUserId: user?.ReviewUserId,
            // };
            grid.value.refresh();
            newRowModal.visible = false;
          }, (reason) => {
            CloudFun.send("error", {
              subject: "保存失敗",
              content: reason,
            });
          });
      },
      reset() {
        newRowModal.formData = {
          TopicType: 0,
          ContentType: "",
          SourceType: "",
        };
      }
    })
    const newRowFormOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入標題', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'TopicType',
          title: '主題',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => onTopicTypeChange(newRowProgramSelect, e.data) } }
        },
        {
          field: 'Programs',
          title: '產業類別',
          span: 12,
          slots: { default: "column-programs" }
        },
        {
          field: 'ContentType',
          title: '內容類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.ContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SourceType',
          title: '來源類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.SourceType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ReportUserId',
          title: '負責人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        {
          field: 'HideReportUser',
          title: '出版時不顯示負責人於作者欄',
          span: 12,
          titleWidth: 240,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Keywords',
          title: '關鍵字',
          span: 24,
          slots: { default: "column-keywords" }
        },
      ],
      rules: {
        Title: [{ required: true }],
        PublishedDate: [{ required: true }],
        TopicType: [{ required: true }],
        ReportDate: [{ required: true }],
        ReportUserId: [{ required: true }],
        ProofUserId: [{ required: true }],
        ProofDate: [{ required: true }],
        ReviewUserId: [{ required: true }],
        ReviewDate: [{ required: true }],
        SourceType: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const showEditSlot = (row: any) => {
      if (row.data.Id) return true;
      return false;
    }

    const form = ref<any>();
    const formOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入標題' } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'TopicType',
          title: '主題',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => onTopicTypeChange(editRowProgramSelect, e.data) } }
        },
        {
          field: 'ProgramIds',
          title: '產業類別',
          span: 12,
          slots: { default: "column-program-ids" }
        },
        {
          field: 'ContentType',
          title: '內容類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.ContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SourceType',
          title: '來源類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.SourceType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ReportUserId',
          title: '負責人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        {
          field: 'HideReportUser',
          title: '出版時不顯示負責人於作者欄',
          span: 12,
          titleWidth: 240,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Keywords',
          title: '關鍵字',
          span: 24,
          slots: { default: "column-keywords" },
          visibleMethod: showEditSlot
        },
        {
          span: 24,
          // titleWidth: 80,
          slots: { default: "column-authors-list" }
        },
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.PublicationStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        // {
        //   field: 'Price',
        //   title: '價格',
        //   span: 12,
        //   titleWidth: 80,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入價格' }, }
        // },
        {
          field: 'Page',
          title: '頁數',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入頁數' }, }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          // titleWidth: 80,
          itemRender: { name: '$textarea' }
        },
        // {
        //   field: 'Abstract.Content',
        //   title: '摘要',
        //   span: 24,
        //   titleWidth: 80,
        //   itemRender: { name: '$textarea' }
        // },
        { span: 24, slots: { default: 'html-editer' } },
        {
          span: 24,
          // titleWidth: 80,
          slots: { default: "column-file-upload" }
        },
      ],
      rules: {
        Title: [{ required: true }],
        PublishedDate: [{ required: true }],
        TopicType: [{ required: true }],
        ReportUserId: [{ required: true }],
        ReportDate: [{ required: true }],
        ProofUserId: [{ required: true }],
        ProofDate: [{ required: true }],
        ReviewUserId: [{ required: true }],
        ReviewDate: [{ required: true }],
        ProgramIds: [
          { required: true },
          {
            validator({ itemValue }) {
              if (!disableProgramSelect.value && (!itemValue || itemValue.length === 0)) {
                return new Error("請選擇至少一項產業類別");
              }
            }
          }
        ],
        //ProgramIds: [{ required: true, min: 1, message: "請選擇至少一項產業類別" }],
        SourceType: [{ required: true }],
        ContentType: [{ required: true }],
        HideReportUser: [{ required: true }],
        Status: [{ required: true }],
      }
    }

    const programSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => model!.dispatch("program/getChildOnly", { ...params, type: selectedTopicType.value }) // eslint-disable-line
      }
    };

    const allProgramSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇產業類別",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      dropdownWidth: 420,
      dropdownHeight: 462,
      pagerConfig: {
        size: "mini",
        currentPage: 1,
        pageSize: 10,
        layouts: ["PrevPage", "Jump", "PageCount", "NextPage"],
        autoHidden: true,
        className: "border-b-2 rounded-b",
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("HasChild", Operator.Equal, false).and(params.condition!);
          return model!.dispatch("program/query", params) // eslint-disable-line
        },
      }
    };

    const keywordSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇關鍵字",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("keyword/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          return model!.dispatch("keyword/query", params) // eslint-disable-line
        },
        insert: (value) => {
          var data = { entity: { Name: value.Name, Published: true } };
          return model!.dispatch("keyword/insert", data)
        },
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    const allUserSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇撰寫人",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      dropdownWidth: 600,
      dropdownHeight: 462,
      pagerConfig: {
        size: "mini",
        currentPage: 1,
        pageSize: 10,
        layouts: ["PrevPage", "Jump", "PageCount", "NextPage"],
        autoHidden: true,
        className: "border-b-2 rounded-b",
      },
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    const getLastFile = (key: number) => {
      return model?.dispatch('document/lastFile', key);
    }

    const authorTable = ref<any>()

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            const condition = new Condition("Type", Operator.In, [3, 4, 5]);

            const availableStatuses = [enums.DocumentStatus.Writing.Value, enums.DocumentStatus.ChangedReturn.Value, enums.DocumentStatus.Changed.Value, enums.DocumentStatus.ReviewedReturn.Value, enums.DocumentStatus.ProofReadReturn.Value];
            const record = fileModal.selectedRow.DocumentId
              ? (await model!.dispatch("documentRecord/query", {
                condition: new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.DocumentId)
                  .and("BeforeStatus", Operator.In, availableStatuses),
                sortings: [{ column: "CreatedTime", order: 1 }],
                page: 1,
                pageSize: 1
              })).data?.[0]
              : null;
            if (record) {
              condition.and(
                new Condition("DocumentRecordId", Operator.Equal, record.Id)
                  .or("PublicationId", Operator.Equal, fileModal.selectedRow.Id))
            } else {
              condition.and("PublicationId", Operator.Equal, fileModal.selectedRow.Id)
            }
            const sortings = [{ column: "CreatedTime", order: 1 }];
            return model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Publication_' + fileModal.selectedRow.Id, fileType: 5 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        //fileModal.visible = true;
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const classicEditor = ClassicEditor;
    const FormConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };
    const ChapterConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }
    const DiagramConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }
    const ContentConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }
    const AbstractConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }

    const onGridAdd = (row: any, callback: any) => {
      // newRowModal.visible = true;
      // newRowModal.formData.TopicType = 0;
      // newRowModal.formData.SourceType = "";
      // newRowModal.formData.ContentType = "";
      // newRowProgramSelect.value.grid.refresh();

      //row.TopicType = 0;
      row.Price = 0;
      row.Page = 0;
      row.SourceType = "";
      row.ContentType = "";
      row.Status = 0;
      row.Abstract = { Content: '' }
      row.Form = { Content: '' }
      row.Chapter = { Content: '' }
      row.Diagram = { Content: '' }
      row.Content = { Content: '' }
      callback()
    }

    const submitData = (row: any, callback: any) => {
      row.Authors = authorTable.value.getData();
      //console.log(row.Authors)
      callback()
    }

    const footerSubmit = () => {
      //console.log(disableProgramSelect.value)
      form.value.validate((errMap: any) => {
        //console.log(errMap)
        if (errMap === undefined) {
          isDisabled.value = true;
          form.value.dispatchEvent('submit');
        }
      })
    }

    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('publication/find', row.Id) : undefined;
      fileModal.show(row)
      onTopicTypeChange(editRowProgramSelect, row);
      Object.assign(row, entity);
      callback();
    }

    const feedbackGrid = ref<any>()
    const feedbackModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("PublicationId", Operator.Equal, feedbackModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("feedback/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('feedback/query') : undefined,
          save: model ? (params) => model.dispatch('feedback/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        feedbackModal.visible = true;
        feedbackModal.selectedRow = row;
        if (feedbackGrid.value) feedbackGrid.value.refresh();
      }
    })

    return {
      grid,
      publicationStatus,
      gridOptions,
      newRowForm,
      newRowModal,
      newRowFormOptions,
      form,
      formOptions,
      model,
      programSelectorOptions,
      keywordSelectorOptions,
      userSelectorOptions,
      authorTable,
      fileGrid,
      fileModal,
      classicEditor,
      FormConfig,
      ChapterConfig,
      DiagramConfig,
      ContentConfig,
      AbstractConfig,
      onGridAdd,
      onGridEdit,
      newRowProgramSelect,
      editRowProgramSelect,
      submitData,
      footerSubmit,
      disableProgramSelect,
      feedbackGrid,
      feedbackModal,
      programIds,
      userIds,
      allProgramSelectorOptions,
      allUserSelectorOptions,
      isDisabled,
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    },
    hasApproved(status: number) { return status === (this.$model.enums.DocumentStatus as any).Approved.Value }
  }
})
</script>

<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}

.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>
