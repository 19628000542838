<template>
  <fieldset class="border rounded my-2 ml-4 p-2">
    <div v-if="!readonly" class="flex items-center mx-1 mb-2">
      新增共同作者或外部撰稿人
      <button type="button" class="ml-auto" @click="table.insert">
        <FontAwesome icon="plus" class="w-5 h-5 text-yellow-500" />
      </button>
    </div>
    <vxe-table ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Department" title="單位" sortable></vxe-column>
      <vxe-column field="Name" title="姓名" sortable>
        <template #default="{ row }">
          <span v-if="row.OutSource">{{ row.Name }}</span>
          <span v-else>{{ row.Author?.Name }}</span>
        </template>
      </vxe-column>
      <vxe-column field="Position" title="職稱" sortable></vxe-column>
      <vxe-column field="Ordinal" title="排序" sortable>
      </vxe-column>
      <vxe-column v-if="!readonly" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-modal v-model="table.isEditing" :title="table.selectedRow ? '编辑作者' : '新增作者'" width="70%" min-width="600"
      height="320" :loading="table.isLoading" resize destroy-on-close showFooter show-zoom="true">
      <template #default>
        <vxe-form ref="form" :data="table.formData" titleAlign="right" @submit="table.save">
          <vxe-form-item title="是否為外部作者" field="OutSource" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-checkbox v-model="data.OutSource" :checked-value="true" :unchecked-value="false"
                @change="evt => { if (evt.checked) { data.Name = null; } else { data.AuthorId = null; } }">
              </vxe-checkbox>
            </template>
          </vxe-form-item>
          <vxe-form-item :item-render="{}" span="12"></vxe-form-item>
          <vxe-form-item title="單位" field="Department" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Department" placeholder="請輸入單位" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="姓名" field="Name" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-if="data.OutSource" v-model="data.Name" placeholder="請輸入姓名" clearable></vxe-input>
              <select-box v-else v-bind="userSelectorOptions" v-model="data.AuthorId" />
            </template>
          </vxe-form-item>
          <vxe-form-item title="職稱" field="Fee" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Position" placeholder="請輸入職稱" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item title="排序" field="Ordinal" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input type="number" v-model="data.Ordinal" placeholder="請輸入排序" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="外部來源" field="Source" :item-render="{}" span="24">
            <template #default="{ data }">
              <vxe-textarea v-model="data.Source" placeholder="請輸入外部來源" clearable></vxe-textarea>
             </template>
          </vxe-form-item> -->
        </vxe-form>
      </template>
      <template #footer>
        <vxe-button type="button" status="primary" content="確認"
          @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
        </vxe-button>
      </template>
    </vxe-modal>
  </fieldset>
</template>
<script lang="ts">
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Author {
  OutSource: boolean;
  Name?: string;
  AuthorId?: string;
  Author?: { Id: string, Name: string };
  Source?: string;
  Ordinal: number;
}

export default defineComponent({
  name: "PublicationAuthorTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<Author[]>,
      default: () => []
    },
    readonly: Boolean
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as Author[],
      isLoading: false,
      isEditing: false,
      formData: {} as Author,
      selectedRow: null as Author | null,
      insert() {
        table.formData = { OutSource: false, Ordinal: 0 };
        table.selectedRow = null;
        table.isEditing = true;
      },
      update(row: Author) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: Author) {
        grid.value.remove(row);
      },
      async save() {
        table.isLoading = true;
        if (table.formData.OutSource) {
          table.formData.AuthorId = undefined;
          table.formData.Author = undefined;
        } else {
          table.formData.Name = undefined;
          if (table.formData.AuthorId) {
            const temp = await model?.dispatch("user/find", table.formData.AuthorId)
            table.formData.Author = { Id: temp.Id, Name: temp.Name };
          }
        }
        if (table.selectedRow) {
          Object.assign(table.selectedRow, table.formData)
        } else {
          await grid.value.insert(table.formData);
        }
        grid.value.sort("Ordinal", 'asc');
        table.isEditing = false;
        table.isLoading = false;
      }
    })

    watch(() => props.data, async (value) => {
      const authorIds = value.filter(e => e.AuthorId).map(e => e.AuthorId);
      const authors: Author['Author'][] = await model?.dispatch("user/query", { condition: new Condition("Id", Operator.In, authorIds) });
      table.data = value.map(e => {
        if (e.AuthorId) {
          e.Author = authors.find(a => a && a.Id === e.AuthorId)
          e.OutSource = false;
        } else {
          e.OutSource = true;
        }
        return e;
      }).sort((a, b) => a.Ordinal - b.Ordinal);
    }, { immediate: true });

    const form = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };
    return {
      table,
      grid,
      gridOptions,
      form,
      userSelectorOptions
    }
  },
  methods: {
    getData() {
      // 避免 Author 帶過多關聯使的API維護Mapping
      return this.grid.getTableData().fullData.map((e: Author) => {
        delete e.Author;
        return e;
      });
    }
  }
})
</script>
