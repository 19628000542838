
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  props: {
    sid: Number,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '迴響',
      canCreate: false,
      canUpdate: false,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      toolbarConfig: {
        refresh: true
      },
      columns: [
        { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm') : '' },
        { field: 'Member.Person.Name', title: '會員名稱', showOverflow: true, sortable: true, resizable: false },
        { field: 'Score', title: '文章評分', showOverflow: true, sortable: true, resizable: false,
          formatter: ({ cellValue }) => {
            const item: any = Scores.find((e: any) => e.value === cellValue);
            return item ? item.label : "";
          }
        },
        { field: 'UserIp', title: '使用者IP', showOverflow: true, sortable: true, resizable: false },        
        
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "330", showFooter: true },
    }

    const Scores = [
      { label: "非常滿意", value: 1 },
      { label: "滿意", value: 2 },
      { label: "普通", value: 3 },
      { label: "尚可", value: 4 },
      { label: "不滿意", value: 5 },
      { label: "非常不滿意", value: 6 },
    ]

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        {
          field: 'MemberId',
          title: '會員名稱',
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: 'Score',
          title: '文章評分',
          span: 12,
          //itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入評分', disabled: true }, }
          itemRender: { name: '$select', props: { disabled: 'true' }, options: Scores }
        },
        {
          field: 'UserIp',
          title: '使用者IP',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true, disabled: true } }          
        },
        { field: 'Comment', title: '觀點分享', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: true } } },
      ],
      rules:{
        StartDate: [{ type: 'string', required: true }],
        EndDate: [{ type: 'string', required: true }],
        Enabled: [{ required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      row.Enabled = false;
      row.MemberId = props.sid;
      callback();
    }

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    const close = () => {
      grid.value.isModalPopup = false;
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      memberSelectorOptions,
      close,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
