
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import AuthorTable from "@/components/publication/AuthorTable.vue";
import FeedbackTable from "@/components/publication/FeedbackTable.vue";
import PublicationFileUpload from "@/components/publication/PublicationFileUpload.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import { Ref, watch } from 'vue';
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AuthorTable,
    PublicationFileUpload,
    FeedbackTable,
  },
  setup() {
    const model = CloudFun.current?.model;
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({});
    const disableProgramSelect = ref(false);
    const selectedTopicType = ref<number>(0);
    const newRowProgramSelect = ref<any>();
    const editRowProgramSelect = ref<any>();
    const publicationStatus = ref();
    const programIds = ref();
    const userIds = ref();
    const isDisabled = ref(false);

    const onTopicTypeChange = async (instance: Ref<any>, data: any) => {
      //console.log("topic", data)
      selectedTopicType.value = data.TopicType;
      data.ProgramIds = [];
      disableProgramSelect.value = (await model!.dispatch("program/getChildOnly", { mode: "count", type: selectedTopicType.value })) < 1;
      //console.log(disableProgramSelect.value)
      if (instance.value && instance.value.grid.refresh) {
        instance.value.grid.refresh();
      }
    };

    const setTopicType = async (instance: Ref<any>) => {
      if (instance.value && instance.value.grid.refresh) {
        instance.value.grid.refresh();
      }
    };

    const gridOptions: GridOptions = {
      stripe: false,
      title: '出版品',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      columns: [
      {
        field: 'IndustryCategoryName',
        title: '產業類別',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: false,
      },
      {
        field: 'Title',
        title: '標題',
        width: '40%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-title' }
      },
      {
        field: 'ReportUser.Name',
        title: '撰寫人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'Document.ProofUser.Name',
        title: '校稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'Document.ReviewUser.Name',
        title: '審稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'PublishedDate',
        title: '出版截止時間',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd')
      },
      {
        field: 'Status',
        title: '狀態',
        // width: '100',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.PublicationStatus).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      // {
      //   title: '檔案列表',
      //   width: '80',
      //   slots: { default: "file-list" }
      // },
      // {
      //   field: 'TopicType',
      //   title: '主題',
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.TopicType).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      // {
      //   title: '迴響資料',
      //   width: '100',
      //   slots: { default: "feedback" }
      // },
      ],
      promises: {
        query: model ? (params) => {
          let condition = new Condition("Status", Operator.NotEqual, (model.enums.PublicationStatus as any).Revoked.Value);
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if (publicationStatus.value != null) {
            condition = condition.and("Status", Operator.Equal, publicationStatus.value).and(params.condition!);
          }
          if (programIds.value != null && programIds.value.length) {
            // console.log(programIds.value)
            condition = condition.and("DocumentPrograms.ProgramId", Operator.Contains, programIds.value.join()).and(params.condition!);
          }
          if (userIds.value != null && userIds.value.length) {
            // console.log(userIds.value)
            condition = condition.and("ReportUserId", Operator.Contains, userIds.value.join()).and(params.condition!);
          }
          params.condition?.and(condition);
          return model.dispatch('publication/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('publication/query') : undefined,
        save: model ? (params) => model.dispatch('publication/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "80%", showFooter: true },
      treeConfig: { children: 'Children' }
    }

    const newRowForm = ref(null);
    const newRowModal = reactive({
      visible: false,
      formData: {
        TopicType: 0,
        ContentType: "",
        SourceType: "",
      },
      submit() {
        if (!model) return;
        model.dispatch('publication/insert', newRowModal.formData)
          .then(() => {
            // newRowModal.formData = {
            //   ProofUserId: user?.ProofUserId,
            //   ReviewUserId: user?.ReviewUserId,
            // };
            grid.value.refresh();
            newRowModal.visible = false;
          }, (reason) => {
            CloudFun.send("error", {
              subject: "保存失敗",
              content: reason,
            });
          });
      },
      reset() {
        newRowModal.formData = {
          TopicType: 0,
          ContentType: "",
          SourceType: "",
        };
      }
    })
    const newRowFormOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入標題', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'TopicType',
          title: '主題',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => onTopicTypeChange(newRowProgramSelect, e.data) } }
        },
        {
          field: 'Programs',
          title: '產業類別',
          span: 12,
          slots: { default: "column-programs" }
        },
        {
          field: 'ContentType',
          title: '內容類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.ContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SourceType',
          title: '來源類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.SourceType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ReportUserId',
          title: '負責人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        {
          field: 'HideReportUser',
          title: '出版時不顯示負責人於作者欄',
          span: 12,
          titleWidth: 240,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Keywords',
          title: '關鍵字',
          span: 24,
          slots: { default: "column-keywords" }
        },
      ],
      rules: {
        Title: [{ required: true }],
        PublishedDate: [{ required: true }],
        TopicType: [{ required: true }],
        ReportDate: [{ required: true }],
        ReportUserId: [{ required: true }],
        ProofUserId: [{ required: true }],
        ProofDate: [{ required: true }],
        ReviewUserId: [{ required: true }],
        ReviewDate: [{ required: true }],
        SourceType: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const showEditSlot = (row: any) => {
      if (row.data.Id) return true;
      return false;
    }

    const form = ref<any>();
    const formOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入標題' } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'TopicType',
          title: '主題',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => onTopicTypeChange(editRowProgramSelect, e.data) } }
        },
        {
          field: 'ProgramIds',
          title: '產業類別',
          span: 12,
          slots: { default: "column-program-ids" }
        },
        {
          field: 'ContentType',
          title: '內容類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.ContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SourceType',
          title: '來源類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.SourceType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ReportUserId',
          title: '負責人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        {
          field: 'HideReportUser',
          title: '出版時不顯示負責人於作者欄',
          span: 12,
          titleWidth: 240,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Keywords',
          title: '關鍵字',
          span: 24,
          slots: { default: "column-keywords" },
          visibleMethod: showEditSlot
        },
        {
          span: 24,
          // titleWidth: 80,
          slots: { default: "column-authors-list" }
        },
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.PublicationStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        // {
        //   field: 'Price',
        //   title: '價格',
        //   span: 12,
        //   titleWidth: 80,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入價格' }, }
        // },
        {
          field: 'Page',
          title: '頁數',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入頁數' }, }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          // titleWidth: 80,
          itemRender: { name: '$textarea' }
        },
        // {
        //   field: 'Abstract.Content',
        //   title: '摘要',
        //   span: 24,
        //   titleWidth: 80,
        //   itemRender: { name: '$textarea' }
        // },
        { span: 24, slots: { default: 'html-editer' } },
        {
          span: 24,
          // titleWidth: 80,
          slots: { default: "column-file-upload" }
        },
      ],
      rules: {
        Title: [{ required: true }],
        PublishedDate: [{ required: true }],
        TopicType: [{ required: true }],
        ReportUserId: [{ required: true }],
        ReportDate: [{ required: true }],
        ProofUserId: [{ required: true }],
        ProofDate: [{ required: true }],
        ReviewUserId: [{ required: true }],
        ReviewDate: [{ required: true }],
        ProgramIds: [
          { required: true },
          {
            validator({ itemValue }) {
              if (!disableProgramSelect.value && (!itemValue || itemValue.length === 0)) {
                return new Error("請選擇至少一項產業類別");
              }
            }
          }
        ],
        //ProgramIds: [{ required: true, min: 1, message: "請選擇至少一項產業類別" }],
        SourceType: [{ required: true }],
        ContentType: [{ required: true }],
        HideReportUser: [{ required: true }],
        Status: [{ required: true }],
      }
    }

    const programSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => model!.dispatch("program/getChildOnly", { ...params, type: selectedTopicType.value }) // eslint-disable-line
      }
    };

    const allProgramSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇產業類別",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      dropdownWidth: 420,
      dropdownHeight: 462,
      pagerConfig: {
        size: "mini",
        currentPage: 1,
        pageSize: 10,
        layouts: ["PrevPage", "Jump", "PageCount", "NextPage"],
        autoHidden: true,
        className: "border-b-2 rounded-b",
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("HasChild", Operator.Equal, false).and(params.condition!);
          return model!.dispatch("program/query", params) // eslint-disable-line
        },
      }
    };

    const keywordSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇關鍵字",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("keyword/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          return model!.dispatch("keyword/query", params) // eslint-disable-line
        },
        insert: (value) => {
          var data = { entity: { Name: value.Name, Published: true } };
          return model!.dispatch("keyword/insert", data)
        },
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    const allUserSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇撰寫人",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      dropdownWidth: 600,
      dropdownHeight: 462,
      pagerConfig: {
        size: "mini",
        currentPage: 1,
        pageSize: 10,
        layouts: ["PrevPage", "Jump", "PageCount", "NextPage"],
        autoHidden: true,
        className: "border-b-2 rounded-b",
      },
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    const getLastFile = (key: number) => {
      return model?.dispatch('document/lastFile', key);
    }

    const authorTable = ref<any>()

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            const condition = new Condition("Type", Operator.In, [3, 4, 5]);

            const availableStatuses = [enums.DocumentStatus.Writing.Value, enums.DocumentStatus.ChangedReturn.Value, enums.DocumentStatus.Changed.Value, enums.DocumentStatus.ReviewedReturn.Value, enums.DocumentStatus.ProofReadReturn.Value];
            const record = fileModal.selectedRow.DocumentId
              ? (await model!.dispatch("documentRecord/query", {
                condition: new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.DocumentId)
                  .and("BeforeStatus", Operator.In, availableStatuses),
                sortings: [{ column: "CreatedTime", order: 1 }],
                page: 1,
                pageSize: 1
              })).data?.[0]
              : null;
            if (record) {
              condition.and(
                new Condition("DocumentRecordId", Operator.Equal, record.Id)
                  .or("PublicationId", Operator.Equal, fileModal.selectedRow.Id))
            } else {
              condition.and("PublicationId", Operator.Equal, fileModal.selectedRow.Id)
            }
            const sortings = [{ column: "CreatedTime", order: 1 }];
            return model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Publication_' + fileModal.selectedRow.Id, fileType: 5 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        //fileModal.visible = true;
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const classicEditor = ClassicEditor;
    const FormConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };
    const ChapterConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }
    const DiagramConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }
    const ContentConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }
    const AbstractConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }

    const onGridAdd = (row: any, callback: any) => {
      // newRowModal.visible = true;
      // newRowModal.formData.TopicType = 0;
      // newRowModal.formData.SourceType = "";
      // newRowModal.formData.ContentType = "";
      // newRowProgramSelect.value.grid.refresh();

      //row.TopicType = 0;
      row.Price = 0;
      row.Page = 0;
      row.SourceType = "";
      row.ContentType = "";
      row.Status = 0;
      row.Abstract = { Content: '' }
      row.Form = { Content: '' }
      row.Chapter = { Content: '' }
      row.Diagram = { Content: '' }
      row.Content = { Content: '' }
      callback()
    }

    const submitData = (row: any, callback: any) => {
      row.Authors = authorTable.value.getData();
      //console.log(row.Authors)
      callback()
    }

    const footerSubmit = () => {
      //console.log(disableProgramSelect.value)
      form.value.validate((errMap: any) => {
        //console.log(errMap)
        if (errMap === undefined) {
          isDisabled.value = true;
          form.value.dispatchEvent('submit');
        }
      })
    }

    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('publication/find', row.Id) : undefined;
      fileModal.show(row)
      onTopicTypeChange(editRowProgramSelect, row);
      Object.assign(row, entity);
      callback();
    }

    const feedbackGrid = ref<any>()
    const feedbackModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("PublicationId", Operator.Equal, feedbackModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("feedback/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('feedback/query') : undefined,
          save: model ? (params) => model.dispatch('feedback/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        feedbackModal.visible = true;
        feedbackModal.selectedRow = row;
        if (feedbackGrid.value) feedbackGrid.value.refresh();
      }
    })

    return {
      grid,
      publicationStatus,
      gridOptions,
      newRowForm,
      newRowModal,
      newRowFormOptions,
      form,
      formOptions,
      model,
      programSelectorOptions,
      keywordSelectorOptions,
      userSelectorOptions,
      authorTable,
      fileGrid,
      fileModal,
      classicEditor,
      FormConfig,
      ChapterConfig,
      DiagramConfig,
      ContentConfig,
      AbstractConfig,
      onGridAdd,
      onGridEdit,
      newRowProgramSelect,
      editRowProgramSelect,
      submitData,
      footerSubmit,
      disableProgramSelect,
      feedbackGrid,
      feedbackModal,
      programIds,
      userIds,
      allProgramSelectorOptions,
      allUserSelectorOptions,
      isDisabled,
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    },
    hasApproved(status: number) { return status === (this.$model.enums.DocumentStatus as any).Approved.Value }
  }
})
